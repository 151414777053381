//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppHeaderDesktop from '../components/header/AppHeaderDesktop.vue'
import AppHeaderMobile from '../components/header/AppHeaderMobile.vue'
import AppFooterMobileSticky from '../components/footer/AppFooterMobileSticky.vue'

export default {
  components: {
    AppHeaderDesktop,
    AppHeaderMobile,
    AppFooterMobileSticky,
  },

  data: () => ({
    alert: {
      show: false,
      message: '',
      variant: '',
    },
  }),

  mounted() {
    this.$bus.$on('alert', ({ message, variant }) => {
      this.alert.show = true
      this.alert.message = message
      this.alert.variant = variant || 'info'
      setTimeout(() => (this.alert.show = false), 5000)
    })
  },
}
