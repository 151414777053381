//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppFooterMobileActive from './AppFooterMobileActive.vue'
import AppFooterMobileButton from './AppFooterMobileButton.vue'

export default {
  components: {
    AppFooterMobileActive,
    AppFooterMobileButton,
  },

  data: () => ({
    showFooter: false,
  }),
}
