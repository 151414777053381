//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppHeader',
  props: {
    metadata: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoUrl() {
      return this.metadata?.logo?.url
    },
  },
}
